import React, { useEffect } from 'react'
import * as d3 from 'd3'
import './error.css'

const Error = () => {
  useEffect(() => {
    d3.select("body").classed("error-background", true)
    return () => {
      d3.select("body").classed("error-background", false)
    }
  }, [])

  return (
    <div id="error-page__container">
      <div id="error-page">
        <h1>404</h1>
        <h3>Page Not Found</h3>
      </div>
    </div>
  );
};

export default Error
